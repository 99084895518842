.sidebar {
  position: fixed;

  width: 260px;
  background-color: white;
  height: 100vh;
  overflow: auto;
  scrollbar-width: none;
  transition: 0.3s;
  &:hover {
    scrollbar-width: thin;
  }
  .container {
    .menu {
      width: "220px";
      .list {
        width: 220px;
        color: #aeaeae;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 4px;
        .icon {
          color: #aeaeae;
        }
        &.active {
          background: var(--btn-background);
          color: white;
          border-radius: 10px;
          .icon {
            color: white;
          }
        }
        &:hover {
          background: var(--btn-background);
          color: white;
          border-radius: 10px;
          .icon {
            color: white;
          }
        }
      }

      .list-item {
        width: 220px;
        color: #aeaeae;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 4px;
        .icon {
          color: #aeaeae;
        }
        &.active {
          background: var(--btn-background);
          color: white;
          border-radius: 10px;
          .icon {
            color: white;
          }
        }
        &:hover {
          background: var(--btn-background);
          color: white;
          border-radius: 10px;
          .icon {
            color: white;
          }
        }
      }

      .collapse-item {
        width: 210px;
        margin: 0px auto;
        color: white;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 5px;
        .icon {
          color: #aeaeae;
        }
        &.active {
          background: white;
          color: var(--main-color);
          border-radius: 10px;

          .icon {
            color: white;
          }
        }
        &:hover {
          background: white;
          color: var(--main-color);
          border-radius: 10px;
          .icon {
            color: white;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .sidebar {
    position: fixed;
    width: 70px !important;
    background-color: white;
    height: 100vh;
    overflow: auto;
    padding-bottom: 90px;
    scrollbar-width: none;
    &:hover {
      scrollbar-width: thin;
    }
    .container {
      width: 100%;
      .menu {
        width: 100%;
        .list {
          width: 100%;
          color: #aeaeae;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          margin-bottom: 4px;
          .icon {
            color: #aeaeae;
          }
          &.active {
            background: var(--btn-background);
            color: white;
            border-radius: 10px;
            .icon {
              color: white;
            }
          }
          &:hover {
            background: var(--btn-background);
            color: white;
            border-radius: 10px;
            .icon {
              color: white;
            }
          }
        }

        .list-item {
          width: 100%;
          color: #aeaeae;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          margin-bottom: 4px;
          .icon {
            color: #aeaeae;
          }
          &.active {
            background: var(--btn-background);
            color: white;
            border-radius: 10px;
            .icon {
              color: white;
            }
          }
          &:hover {
            background: var(--btn-background);
            color: white;
            border-radius: 10px;
            .icon {
              color: white;
            }
          }
          .list-item-title {
            display: none;
          }
        }

        .collapse-item {
          width: 90%;
          margin: 0px auto;
          color: white;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          margin-bottom: 5px;

          .icon {
            color: #aeaeae;
          }
          &.active {
            background: white;
            color: var(--main-color);
            border-radius: 10px;

            .icon {
              color: white;
            }
          }
          &:hover {
            background: white;
            color: var(--main-color);
            border-radius: 10px;
            .icon {
              color: white;
            }
          }
          .collapse-item-title {
            width: 90%;
            span {
              width: 100%;
              font-size: 10px;
              line-height: normal;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .sidebar {
    position: fixed;
    width: 200px;
    background-color: white;
    height: 100vh;
    overflow: auto;
    padding-bottom: 90px;
    scrollbar-width: none;
    &:hover {
      scrollbar-width: thin;
    }

    .container {
      width: 100%;
      .menu {
        list-style: none;
        padding: 0px;
        .menu-item {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 16px;
          text-decoration: none;
          padding: 6px;
          border-radius: 10px;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: #aeaeae;
          cursor: pointer;
          transition: 0.2s;
          margin-bottom: 4px;
          &.active {
            background: var(--btn-background);
            color: white;
          }
          &:hover {
            background: var(--btn-background);
            color: white;
          }
        }
      }
      .accordion {
        width: 100%;
        border: none;
        box-shadow: none;
        border-radius: 10px;
        padding: 0px;
        margin-bottom: 4px !important;
        .menu-item {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 16px;
          text-decoration: none;
          padding: 6px;
          border-radius: 10px;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: white;
          cursor: pointer;
          transition: 0.2s;
          margin: 0px auto 4px;
          &.active {
            background: white;
            color: var(--main-color);
          }
          &:hover {
            background: white;
            color: var(--main-color);
          }
        }
        &:hover {
          background: var(--btn-background);
          color: white;
        }
        .MuiAccordionSummary-content {
          width: 100%;
          gap: 16px;
          align-items: center;
          border: none;
          outline: none;
        }
        .accordion-header {
          padding: 0px 6px;

          &:hover {
            color: white;
          }
          .accordion-link {
            width: 100%;
            text-decoration: none;
            display: flex;
            align-items: center;
            gap: 16px;
            color: #aeaeae;
          }
        }
        &.active {
          background: var(--btn-background);
          color: white !important;
          .accordion-link {
            color: white;
          }
        }
      }
    }
  }
}
