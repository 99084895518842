:root {
  --btn-background: linear-gradient(91deg, #ff7300 0.18%, #ffcd4d 99.68%);
  --main-color: #ff7300;
  --box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.4);
}

* {
  box-sizing: border-box;
  font-family: "Helvetica Neue W23 for SKY" !important;
}
body {
  direction: rtl;
  zoom: 0.9;
  font-family: "Helvetica Neue W23 for SKY" !important;
}

div.MuiDataGrid-toolbarContainer {
  display: none;
}
.cell-img {
  width: 60px;
  height: 52px;
  border-radius: 6px;
}
.data-grid {
  background: #f8f8f8;
}
div.MuiDataGrid-row {
  /* margin-top: 8px !important; */
}

/* Mobile Devices */
@media (max-width: 767px) {
  .container {
    padding: 8px;
    background-color: white;
    width: 100%;
  }
}

/* Tablets */
@media (min-width: 768px) and (max-width: 1023px) {
  .container {
    padding: 16px;
    background-color: white;
    width: 100%;
  }
}

/* Desktops and Laptops */
@media (min-width: 1024px) {
  .container {
    padding: 24px;
    background-color: white;
    width: 100%;
  }
}
