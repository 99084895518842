.create {
  height: 100%;
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    form {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      gap: 24px;

      // Existing form control styles
      .form-control {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        width: 100%;
        input {
          align-self: flex-start;
          width: 50%;
          height: 44px;
          box-shadow: var(--box-shadow);
          padding: 0px 16px;
          border: none;
          outline: none;
          background-color: #f5f5f540;
          border-radius: 10px;
        }
        label {
          align-self: flex-start;
        }

        input[type="file"] {
          display: none;
        }
        .file-label {
          display: flex;
          align-items: center;
          align-self: center;
          border-radius: 10px;
          gap: 16px;
          box-shadow: 0px 0px 0px 2px rgba(201, 201, 201, 0.47);
          height: 40px;
          padding: 0px 10px;
          cursor: pointer;
          .icon {
            font-weight: bolder;
            font-size: 16px;
          }
        }
        span {
          display: flex;
          align-items: center;
          gap: 10px;
          .icon {
            color: blue;
            cursor: pointer;
            width: 20px;
            height: 20px;
            &:hover {
              border-radius: 50%;
              border: 1px solid #777;
            }
          }
        }
      }

      // Existing control group styles
      .control-group {
        display: flex;
        align-items: center;
        width: 100%;
        .form-control {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 24px;
          width: 100%;
          input {
            align-self: flex-start;
            width: 100%;
            height: 44px;
            box-shadow: var(--box-shadow);
            padding: 0px 16px;
            border: none;
            outline: none;
            background-color: #f5f5f540;
            border-radius: 10px;
          }
          label {
            align-self: flex-start;
          }
          .select {
            width: 100%;
            height: 44px;
            box-shadow: var(--box-shadow);
            padding: 0px 16px;
            border: none;
            outline: none;
            background-color: #f5f5f540;
            border-radius: 10px;
          }
        }
      }

      // New examples section styles
      .examples-section {
        width: 100%;
        padding: 20px;
        background-color: #fff;
        border-radius: 15px;
        box-shadow: var(--box-shadow);

        .examples-title {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 20px;
          text-align: right;
          color: #333;
        }

        .examples-grid {
          display: flex;
          flex-direction: column;
          gap: 12px;

          .example-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 16px;
            background-color: #f5f5f540;
            border-radius: 10px;
            transition: all 0.3s ease;

            &:hover {
              background-color: #f0f0f0;
            }

            .example-left {
              display: flex;
              align-items: center;
              gap: 12px;

              .example-checkbox {
                width: 18px;
                height: 18px;
                cursor: pointer;
              }

              .example-label {
                font-size: 16px;
                color: #333;
                text-align: right;
              }
            }

            .example-controls {
              display: flex;
              align-items: center;
              gap: 12px;

              .control-btn {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                border: none;
                background-color: #fff;
                color: #333;
                font-size: 18px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                transition: all 0.2s ease;

                &:hover {
                  background-color: #f0f0f0;
                  transform: scale(1.05);
                }

                &:active {
                  transform: scale(0.95);
                }

                &.disabled {
                  background-color: #e0e0e0;
                  cursor: not-allowed;
                  opacity: 0.6;

                  &:hover {
                    background-color: #e0e0e0;
                    transform: none;
                  }

                  &:active {
                    transform: none;
                  }
                }
              }

              .count-input {
                width: 60px;
                text-align: center;
                font-size: 16px;
                font-weight: 500;
                color: #333;
                border: 1px solid #ddd;
                border-radius: 4px;
                padding: 4px;
                outline: none;

                &:disabled {
                  background-color: #f5f5f5;
                  cursor: not-allowed;
                }

                &:focus {
                  border-color: var(--main-color);
                  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
                }

                /* Remove spinner buttons */
                &::-webkit-inner-spin-button,
                &::-webkit-outer-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }
                &[type="number"] {
                  -moz-appearance: textfield;
                }
              }
            }
          }
        }
      }
    }
  }
}

// Existing media queries
@media (max-width: 767px) {
  .create {
    .container {
      form {
        justify-content: flex-start;

        .examples-section {
          padding: 15px;

          .example-row {
            padding: 10px;

            .example-label {
              font-size: 14px;
            }

            .example-controls {
              gap: 8px;

              .control-btn {
                width: 26px;
                height: 26px;
                font-size: 16px;
              }

              .count {
                min-width: 26px;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
  .modal {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .message {
      width: 90%;
      height: auto;
      top: 366px;
      left: 445px;
      gap: 0px;
      border-radius: 20px;
      opacity: 0px;
      background-color: white;
      padding: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 24px;
      .header {
        display: flex;
        align-items: center;
        gap: 24px;
      }
      .body {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .form-select {
          display: flex;
          align-items: center;
          gap: 16px;
          label {
            display: block;
            margin-bottom: 8px;
            color: #111;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            order: 1;
          }
          input[type="radio"] {
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 20px;
            height: 20px;
            border: 2px solid #ccc;
            border-radius: 50%;
            outline: none;
            position: relative;
            &:checked {
              border-color: var(--main-color);
              &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: var(--btn-background);
              }
            }
          }
        }
      }
      .footer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .create {
    .container {
      form {
        justify-content: flex-start;
      }
    }
  }
}
